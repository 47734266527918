/***** -----------------------------
ページ読み込み時に処理
----------------------------- ******/
$(function () {
  headerMenu();
  accordion();
  smoothScroll();
  pageTop();
  moreShow();
  moreRead();
  tooltip();
});

/***** -----------------------------
ヘッダーメニュー
----------------------------- ******/
function headerMenu() {
  const $trigger = $(".js-header-menu-trigger");
  $panel = $(".js-header-menu-panel");

  //ナビを表示
  $trigger.on("click", function () {
    const $parents = $(this).parents(".js-header");
    $overlay = $(".js-overlay-bg");

    if ($parents.hasClass("is-opened")) {
      $parents.removeClass("is-opened");
      $overlay.removeClass("is-actived");
      $panel.slideUp();
    } else {
      $parents.addClass("is-opened");
      $overlay.addClass("is-actived");
      $panel.slideDown();
    }

    // ページ内リンクをクリックでメニューを閉じる
    $(".js-smooth-scroll").on("click", function () {
      if ($parents.hasClass("is-opened")) {
        $parents.removeClass("is-opened");
        $panel.slideUp();
        $overlay.removeClass("is-actived");
      }
    });

    // 背景をクリックでメニューを閉じる
    $overlay.on("click", function () {
      if ($parents.hasClass("is-opened")) {
        $parents.removeClass("is-opened");
        $panel.slideUp();
        $overlay.removeClass("is-actived");
      }
    });
  });
}

/***** -----------------------------
アコーディオン
----------------------------- ******/
function accordion() {
  const $trigger = $(".js-accordion-trigger"),
    $target = $(".js-accordion-target");

  $trigger.click(function () {
    if ($(this).hasClass("is-opened")) {
      // アコーディオンを閉じる処理
      $(this).next($target).stop().slideUp();
      $(this).removeClass("is-opened");
    } else {
      // アコーディオンを開く処理
      $(this).next($target).stop().slideDown();
      $(this).addClass("is-opened");
    }
  });
}

/***** -----------------------------
スムーズスクロール
----------------------------- ******/
function smoothScroll() {
  $.easing.quart = function (x, t, b, c, d) {
    return -c * ((t = t / d - 1) * t * t * t - 1) + b;
  };

  //URLのハッシュ値を取得
  var urlHash = location.hash;
  //ハッシュ値があればページ内スクロール

  if (urlHash) {
    //スクロールを0に戻す
    $("html, body").stop().scrollTop(0);
    setTimeout(function () {
      //ロード時の処理を待ち、時間差でスクロール実行
      scrollToAnchor(urlHash);
    }, 2000);
  }

  scroll();
}

function scroll() {
  $(".js-smooth-scroll").on("click", function () {
    var speed = 400,
      href = $(this).attr("href"),
      target = $(href == "#" || href == "" ? "html" : href);
    scrollToAnchor(target);

    // return false; URLに追加必要
  });
}

function scrollToAnchor(hash) {
  var target = $(hash),
    header = $(".js-header").height(),
    position = target.offset().top,
    position = position < header ? position : position - header - 20; //固定ヘッダー分+α　を適宜追加する

  $("html, body").stop().animate(
    {
      scrollTop: position
    },
    1200,
    "quart"
  );
}

/***** -----------------------------
ページトップに戻る
----------------------------- ******/
function pageTop() {
  window.addEventListener("scroll", function () {
    const elm = document.querySelector(".js-pagetop");
    const scroll = window.scrollY;
    // for elm
    if (scroll > 100) {
      elm.style.opacity = "1";
      // console.log(scroll);
    } else if (scroll < 100) {
      elm.style.opacity = "0";
      // console.log(scroll);
    }
    // for elm2
    // バーナーがPC版かSP版かで処理分岐
    if ($(".is-onlyPC.js-pagetop-banner").css("display") !== "none") {
      const elm2 = document.querySelector(".is-onlyPC.js-pagetop-banner");
      if (scroll > 500) {
        elm2.style.opacity = "1";
      } else if (scroll < 500) {
        elm2.style.opacity = "0";
      }
    } else if ($(".is-onlySP.js-pagetop-banner").css("display") !== "none") {
      const elm2 = document.querySelector(".is-onlySP.js-pagetop-banner");
      const elm_position = $(".is-onlySP.modal-popup-banner").height() + 20;
      if (scroll > 100) {
        elm2.style.opacity = "1";
        elm.style.bottom = elm_position + "px";
      } else if (scroll < 100) {
        elm2.style.opacity = "0";
        elm.style.bottom = "20px";
      }
    }
  });
}

/***** -----------------------------
もっと見る
----------------------------- ******/
function moreShow() {
  // 初期表示する件数
  const initialDisplayCount = 8;

  // ボタンのクラス名
  const buttonClassName = ".js-more-trigger";

  // ボタンの要素にクリックイベントを追加する関数
  function addClickEventToButtons(buttons) {
    buttons.each(function () {
      const button = $(this);
      const list = button.prevAll(".js-more-target");
      const items = list.find("li");

      // 初期表示する件数の要素以降を非表示にする関数
      function hideItems() {
        for (let j = initialDisplayCount; j < items.length; j++) {
          $(items[j]).hide();
          list.addClass("is-hidden");
        }
      }

      // ボタンがクリックされたときの処理
      function handleClick() {
        if (button.hasClass("is-opened")) {
          hideItems();
          button.removeClass("is-opened");
          list.addClass("is-hidden");
        } else {
          // 全ての要素を表示する
          items.show();
          button.addClass("is-opened");
          list.removeClass("is-hidden");
        }
      }

      // 初期表示する件数の要素以降を非表示にする
      hideItems();

      // ボタンにクリックイベントを追加
      button.on("click", handleClick);
    });
  }

  // ボタンの要素を取得
  const buttons = $(buttonClassName);

  // ボタンにクリックイベントを追加
  addClickEventToButtons(buttons);
}

/***** -----------------------------
続きを読む
----------------------------- ******/
// 初回表示時のcss指定の高さを配列で取得する
var initialOffsetHArray = [];

function moreRead() {
  // 要素の高さに合わせてボタンを表示
  function buttonVisibility() {
    $(".js-more-read-open").each(function (index) {
      var scrollH = this.scrollHeight; // スクロールを含む高さ
      var hiddenH = scrollH - initialOffsetHArray[index]; // css指定の高さ 配列で取得した値を順番に格納

      var $buttonBox = $(this).parent().find(".voice-card-pap__button-box"); // ボタンボックス
      var $button = $buttonBox.find(".js-more-read-trigger"); // ボタン単体

      if (hiddenH > 0) {
        if ($buttonBox.length === 0) {
          $(this)
            .parent()
            .append(
              '<div class="voice-card-pap__button-box"><button class="button-more-pap js-more-read-trigger">続きを読む</button></div>'
            );
        }
        if (!$button.hasClass("is-opened")) {
          $(this).removeClass("is-opened");
        }
      } else {
        $(this).addClass("is-opened");
        $buttonBox.remove();
      }
    });
  }

  // ボタン押下時の挙動
  function buttonToggleText() {
    var $open = $(this).closest(".js-more-read").children(".js-more-read-open");
    var $height = $open.get(0).scrollHeight; // 本来の高さ
    var $button = $(this);

    if ($open.hasClass("is-opened")) {
      //高さを戻す
      $open.css("height", "").removeClass("is-opened");
      //テキストを戻す
      $button.text("もっと見る").addClass("is-opened");
    } else {
      //続きを表示
      $open.addClass("is-opened");
      $open.css("height", $height);
      $button.text("閉じる").addClass("is-opened");
    }
  }

  // 画面リサイズに合わせてボタン表示有無切り替え
  $(window).on("resize", function () {
    buttonVisibility();
  });

  // ロード完了後初期表示用
  window.onload = function () {
    $(".js-more-read-open").each(function () {
      // 初期のcss指定の高さをセット
      initialOffsetHArray.push(this.offsetHeight);
    });
    buttonVisibility();
  };

  // 続きを読むクリックで実行
  $(".js-more-read").on("click", ".js-more-read-trigger", buttonToggleText);
}

function tooltip() {
  //画面幅640以下の場合クリックで開閉
  if ($(window).width() < 640) {
    $(".js-tooltip")
      .off("mouseenter mouseleave")
      .click(function (event) {
        event.stopPropagation();
        var tooltipText = $(this).next(".js-tooltip-text");
        tooltipText.fadeIn();
        $(document).one("click", function (event) {
          if (!$(event.target).closest(".js-tooltip").length) {
            tooltipText.fadeOut();
          }
        });
      });

    //画面幅640以上でホバーで開閉
  } else {
    $(".js-tooltip")
      .off("mouseenter mouseleave")
      .hover(
        function () {
          $(this).next(".js-tooltip-text").fadeIn();
        },
        function () {
          $(this).next(".js-tooltip-text").fadeOut();
        }
      );
  }
}
